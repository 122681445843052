.main_router {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

* {
  touch-action: manipulation;
}

a {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.footer_router {
  width: 100%;
}
